@import '../../../scss/variables';

.PillLink {
  font-size: 20px;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border: none;
    border-radius: 40px;
    font-family: 'Inter', sans-serif;
    padding: 0.7em 1em;
    box-shadow: (0px 4px 50px rgba(0, 0, 0, 0.15));
    font-weight: 500;
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.3s;
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      box-shadow: (0px 4px 50px rgba(0, 0, 0, 0.3));
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  }
}

.ButtonClear {
  @extend button;
  background: rgba(255, 255, 255, 0.2);
  color: white;
}

.ButtonBlue {
  @extend button;
  background-color: #6586ff;
  color: white;
}

.ButtonBlueWide {
  @extend button;
  background-color: #6586ff;
  color: white;
  border-radius: 15px !important;
  padding: 0.8em 4em !important;
}

.ButtonWhite {
  @extend button;
  background-color: white;
  color: #6586ff;
}

.ButtonPurpleWide {
  @extend button;
  background-color: #924df9;
  color: white;
  border-radius: 15px !important;
  padding: 1em 6em !important;
}

.ButtonWhiteWide {
  @extend button;
  background-color: white;
  color: #6586ff;
  border-radius: 15px !important;
  padding: 0.8em 4em !important;
}

@media (min-width: $lg) {
  .PillLink {
    font-size: 16px;
  }
  .ButtonWhiteWide {
    padding: 0.8em 3em !important;
  }
  .ButtonBlueWide {
    padding: 0.8em 3em !important;
  }
}
