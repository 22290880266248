@import '../../../scss/mixins';
@import '../../../scss/style';
@import '../../../scss/variables';

.Landing {
  .Gradient {
    background: $bounce-gradient;
    position: relative;
  }

  h3 {
    font-family: 'Inter', sans-serif;
  }

  section {
    background: #f8f8fb;
    padding-bottom: 60px;
    svg {
      margin-top: -320px;
    }
  }
}

.LogoSlider,
.SliderTrack {
  display: flex;
  width: calc(60px * 10);
  animation: scroll 5s infinite linear;
}

.Slide {
  img {
    width: 60px;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(calc(-120px * 10));
  }
}
