#__next * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.Container {
  max-width: 1100px;
  margin: 0 auto;
}

$spaceamounts: (1, 2, 3, 4, 5);

@each $space in $spaceamounts {
  .m#{$space} {
    margin: #{$space}rem;
  }
  .my#{$space} {
    margin: #{$space}rem 10px;
  }

  .p#{$space} {
    padding: #{$space}rem;
  }
  .py#{$space} {
    padding: #{$space}rem 0;
  }
}
