@import '../../../scss/style';
@import '../../../scss/variables';

.StatsSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: none !important;

  @media (min-width: $sm) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: $md) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
}

.Stat {
  background: white;
  width: 50%;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  border: 2px solid #f8f8fb;
  padding: 50px 20px;

  img {
    width: 100px;
    height: 100px;
    object-fit: fill;
    filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.3));
  }

  h2 {
    font-size: $mobile-h2-font-size;
    font-weight: 600;
    color: #6386fe;
  }

  h3 {
    margin: 30px 0 0;
    font-size: $mobile-h3-font-size;
    font-weight: 600;
    color: #6386fe;
  }

  .Title {
    font-size: 20px;
    font-weight: 600;
    line-height: 2.2rem;
    color: #91a9f8;
  }

  &::after {
    border-right: 1px solid #6586ff;
    content: '';
    display: block;
    height: 160px;
    width: inherit;
    position: absolute;
    opacity: 0.4;
  }

  &:last-child::after {
    display: none;
  }

  @media (min-width: $sm) {
    border: 0px;
    width: 40%;
    margin: 10px;
  }

  @media (min-width: $md) {
    width: 25%;
    margin: 0;

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
      object-position: bottom;
    }

    .Title {
      margin-top: 20px;
      font-weight: 400;
    }
  }
}
