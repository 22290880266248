@import '../../../scss/style';
@import '../../../scss/variables';

.HostEvent {
  background: $bounce-gradient;
  height: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: $mobile-h2-font-size;
    color: white;
    font-weight: 600;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}
