@import '../../../scss/style';
@import '../../../scss/variables';

.Sponsors {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: $mobile-h2-font-size;
    font-weight: 600;
    color: #6386fe;
  }
}

.SponsorsList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 0 10px;
  a {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
  }
  h2 {
    font-size: $mobile-h2-font-size;
    font-weight: 600;
    color: #6386fe;
  }
  img {
    height: 54px;
    margin: 10px;
  }

  @media (min-width: $sm) {
    a {
      width: 25%;
    }
  }

  @media (min-width: $lg) {
    a {
      width: auto;
    }
  }

  @media (min-width: $xl) {
    a {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
