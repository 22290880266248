@import '../../../scss/style';
@import '../../../scss/variables';

.Features {
  z-index: 10;
  margin-top: 120px;
  position: relative;

  @media (min-width: $md) {
    margin-top: 100px;
  }
}

.SectionHeadline {
  display: grid;
  margin-top: 50px;
  margin-bottom: 80px;
  text-align: center;

  h3 {
    margin: 0;
    font-size: $mobile-h3-font-size;
    padding: 0 15px;
    font-weight: 600;
    color: #6386fe;
  }
  p {
    margin-top: 0px;
    font-weight: 400;
    padding: 0 20px;
    margin-bottom: 20px;
    line-height: 25px;
    font-size: $mobile-p-font-size;
  }

  @media (min-width: $sm) {
    margin-top: 100px;
  }

  @media (min-width: $xl) {
    display: grid;
    margin: 0 0 100px 0;
  }
}

.Description {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;
  justify-items: center;

  h2 {
    grid-row-start: 1;
  }

  h3 {
    max-width: 380px;
  }

  p {
    margin: 0;
    font-size: 18px;
    max-width: 500px;
    font-weight: 400;
    grid-row-start: 3;
    line-height: 1.5em;
  }
  .button {
    grid-row-start: 4;
    margin-top: 10px;
  }

  .FeatureVid {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    background: #e6e9f6;
    border-radius: 10px;
    grid-row-start: 2;

    video {
      width: 320px;
      height: 200px;
    }

    &.WideScreen {
      padding: 10px 10px;

      video {
        width: 300px;
        height: 200px;
      }
    }
  }

  @media (min-width: $sm) {
    justify-items: center;

    .FeatureVid {
      width: 400px;
      margin: 20px 0;

      video {
        width: 400px;
        height: 250px;
      }

      &.WideScreen {
        video {
          width: 380px;
          height: 230px;
        }
      }
    }
  }

  @media (min-width: $md) {
    gap: 20px;
    justify-items: center;
  }

  @media (min-width: $xl) {
    gap: 0;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: left;

    h3 {
      margin: 20px 0 0 120px;
      padding: 0;
      grid-row: span 2;
      max-width: none;
    }

    p {
      padding: 0px;
      max-width: none;
      margin: 15px 0 10px 120px;
      line-height: 1.5em;
      grid-row-start: 3;
    }

    .button {
      margin: 20px 0 10px 120px;
      grid-row-start: 4;
    }

    .FeatureVid {
      margin: 0;
      padding: 0 70px;
      grid-column-start: 1;
      grid-row-start: 1;
      grid-row-end: 6;
      width: max-content;
      justify-self: flex-end;

      video {
        width: 400px;
        height: 300px;
      }

      &.WideScreen {
        padding: 20px 20px;

        video {
          width: 500px;
          height: 260px;
          border-radius: 10px;
        }
      }
    }

    &.Reverse {
      .FeatureVid {
        grid-column-start: 2;
      }

      h3 {
        margin-left: 0;
        padding-right: 120px;
      }
      p {
        margin-left: 0;
        padding-right: 120px;
      }
      .button {
        margin-left: 0;
        padding-right: 100px;
      }
    }
  }
}

.ReverseVideo {
  grid-column-start: 1 !important;
  justify-self: flex-start !important;
}
