@import '../../../scss/style';
@import '../../../scss/variables';

.Background {
  position: absolute;
  top: 0;
  height: 900px;
  width: 100%;

  img {
    max-width: none;
  }

  &.Shadow {
    display: hidden;
  }

  .BallContainer {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    max-width: 1700px;
    margin: 0 auto;
  }

  .FullBall {
    position: absolute;
    top: -270px;
    left: -9999px;
    right: -9999px;
    margin: 0 auto;
    width: 900px;
    height: 1200px;
  }

  @media (min-width: $sm) {
    height: 1270px;

    .FullBall {
      top: -200px;
      width: 1270px;
      height: 1270px;
    }
  }

  @media (min-width: $md) {
    height: 1270px;

    .FullBall {
      top: -250px;
      width: 1270px;
      height: 1270px;
    }
  }

  @media (min-width: $lg) {
    height: 1800px;

    .FullBall {
      top: -750px;
      width: 1800px;
      height: 1800px;
    }
  }

  @media (min-width: $xl) {
    .FullBall {
      top: -1126px;
      left: -720px;
      margin: 0;
      width: 1900px;
      height: 1900px;
    }

    &.Shadow {
      .FullBall {
        left: auto;
        right: -260px;
        display: block;
      }
    }
  }

  @media (min-width: $xll) {
    .BallContainer {
      overflow: visible;
    }

    .FullBall {
      top: -1126px;
      left: -520px;
      margin: 0;
      width: 1900px;
      height: 1900px;
    }

    &.Shadow {
      .FullBall {
        left: auto;
        right: -120px;
        display: block;
      }
    }
  }
}

.HeaderBanner {
  padding-top: 30px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -60px;

  @media (min-width: $sm) {
    height: 1125px;
  }

  @media (min-width: $xl) {
    flex-direction: row;
    height: 820px;
    justify-content: space-between;
    margin-bottom: 80px;
  }
}

.HeaderText {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px;
  padding-top: 50px;

  h1 {
    font-size: $mobile-h1-font-size;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.28em;
    color: #fafafa;
  }

  h3 {
    font-size: $mobile-h3-font-size;
    margin: 25px 0;
    font-weight: 300;
    line-height: 1.28em;
    color: #fafafa;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 10px;
    margin-bottom: 10px;

    .storeButton {
      height: 48px;

      &.appStore {
        margin-right: 10px;
      }
    }
  }

  @media (min-width: $sm) {
    h1 {
      font-size: 66px;
    }

    h3 {
      font-size: 34px;
      margin: 40px 0;
    }
  }

  @media (min-width: $md) {
    .buttonContainer {
      margin-bottom: 0px;

      .storeButton {
        height: 64px;

        &.appStore {
          margin-right: 20px;
        }
      }
    }
  }

  @media (min-width: $xl) {
    margin: 0;
    margin-top: 80px;
    text-align: left;
    align-self: start;

    h1 {
      font-weight: 500;
    }

    h3 {
      margin-top: 10px;
      margin-bottom: 30px;
      max-width: 450px;
    }

    .buttonContainer {
      margin-top: 0;
      justify-content: flex-start;

      .storeButton {
        height: 58px;

        &.appStore {
          margin-right: 15px;
        }
      }
    }
  }
}

.iPhoneMockup {
  position: relative;
  margin-top: 12px;
  margin-bottom: 0px;

  img {
    width: 225px;
    position: relative;
    z-index: 40;
  }

  @media (min-width: $sm) {
    margin-top: -25px;

    img {
      width: 300px;
      top: 75px;
    }
  }

  @media (min-width: $xl) {
    margin-top: -65px;
    margin-right: 80px;

    img {
      width: 350px;
      margin: 0 10px;
      border-radius: 25px;
    }
  }
}

.Bubbles {
  position: relative;

  img {
    width: 60px;
    position: absolute;
    border-radius: 50%;

    &.Bubble1 {
      top: -390px;
      left: -30px;
    }

    &.Bubble2 {
      top: -260px;
      right: -30px;
    }

    &.Bubble3 {
      top: -110px;
      left: -30px;
    }
  }

  @media (min-width: $sm) {
    img {
      width: 80px;
      &.Bubble1 {
        top: -460px;
        left: -45px;
      }
      &.Bubble2 {
        top: -280px;
        right: -42px;
      }
      &.Bubble3 {
        top: -75px;
        left: -45px;
      }
    }

    @media (min-width: $xl) {
      img {
        width: 100px;
        &.Bubble1 {
          top: -560px;
          left: -60px;
        }
        &.Bubble2 {
          top: -350px;
          right: -55px;
        }
        &.Bubble3 {
          top: -100px;
          left: -55px;
        }
      }
    }
  }
}
